<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <form>
        <md-card>
          <md-card-header
              class="md-card-header-icon"
              :class="getClass(headerColor)"
          >
            <div class="card-icon">
              <md-icon>translate</md-icon>
            </div>
            <h4 class="title">
              {{ $t(`muc.${oper}`) }}
              <small></small>
            </h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout ">
              <div class="md-layout-item md-small-size-100 md-size-25">
                <md-field>
                  <label>
                    {{ $t(`muc.criterion_variable`) }}
                  </label>
                  <md-input
                      v-model="criterion.criterion_variable"
                      @input="onFieldChange()"
                  ></md-input>
                </md-field>
                <md-field>
                  <label>
                    {{ $t(`muc.criterion_condition`) }}
                  </label>
                  <md-select v-model="criterion.criterion_condition" @input="onFieldChange()">
                    <!--md-option value="between">{{ $t("muc.condition_between") }}</md-option-->
                    <md-option value="equal">{{ $t("muc.condition_equal") }}</md-option>
                  </md-select>
                </md-field>
                <template v-if="isBetweenCondition">
                  <div class="md-layout md-gutter md-alignment-center-space-between">
                    <div class="md-layout-item md-small-size-100 md-size-50" v-for="(val, index) in 2" :key="index">
                      <md-field>
                        <label>
                          {{ $t(`muc.${criterionValueLabel[index]}`) }}
                        </label>
                        <md-input
                            v-model="criterion.criterion_value[index]"
                            @input="onFieldChange()"
                        ></md-input>
                      </md-field>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <md-field>
                    <label>
                      {{ $t(`muc.criterion_value`) }}
                    </label>
                    <md-input
                        v-model="criterion.criterion_value[0]"
                        @input="onFieldChange()"
                    ></md-input>
                  </md-field>
                </template>
                <md-field>
                  <label>
                    {{ $t(`muc.criterion_type`) }}
                  </label>
                  <md-select v-model="criterion.criterion_type" @input="onFieldChange()">
                    <md-option value="bool">bool</md-option>
                    <md-option value="int">int</md-option>
                    <md-option value="numeric">numeric</md-option>
                    <md-option value="text">text</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="md-layout-item md-size-100 text-right">
                <md-button
                    class="md-success"
                    native-type="submit"
                    @click.native.prevent="validate"
                    :disabled="nothingSave"
                >
                  {{ $t('button.save') }}
                </md-button>
                <md-button class="md-accent" @click.stop.prevent="onCancel">
                  {{ $t('button.close') }}
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
//import {SlideYDownTransition} from 'vue2-transitions'
import Swal from 'sweetalert2'

export default {
  name: 'muc-profile-form',
  props: {
    headerColor: {
      type: String,
      default: ''
    },
    oper: String
  },
  components: {
    //SlideYDownTransition
  },
  async created() {
    if (this.oper === "upd") {
      this.criterion = await this.$store.dispatch("GET_UNIT_COSTS_WORK_CRITERION_BY_ID", {
        muc_id: this.mid,
        work_id: this.work_id,
        crit_id: this.crit_id
      })
    }
  },
  data() {
    return {
      mid: this.$route.params.mid,
      work_id: this.$route.params.work_id,
      crit_id: this.$route.params.crit_id,
      criterionValueLabel: ["criterion_value_from", "criterion_value_to"],
      nothingSave: true,
      criterion: {
        criterion_variable: undefined,
        criterion_condition: 'equal',
        criterion_value: []
      }
    }
  },
  methods: {
    onFieldChange() {
      this.nothingSave = false
    },
    onCancel() {
      this.$router.go(-1)
    },
    getClass: function () {
      //return 'md-card-header-' + headerColor + ''
    },
    async validate() {
      const alert = {
        type: 'success',
        text: '',
        footer: ''
      }

      const muc_id = this.mid
      const work_id = this.work_id
      const crit_id = this.crit_id
      if (!this.isBetweenCondition) {
        this.criterion.criterion_value = [this.criterion.criterion_value[0]]
      }
      try {
        if (this.oper === "add") {
          await this.$store.dispatch('ADD_UNIT_COSTS_WORK_CRITERION', {
            muc_id,
            work_id,
            data: this.criterion
          })
          alert.text= this.$t(`muc.record_was_added`)
        } else if (this.oper === "upd") {
          await this.$store.dispatch('UPD_UNIT_COSTS_WORK_CRITERION', {
            muc_id,
            work_id,
            crit_id,
            data: this.criterion
          })
          alert.text= this.$t(`muc.record_was_updated`)
        } else {
          throw "Unknown operation"
        }


        this.nothingSave = true
        Swal.fire(alert).then(() => {
          //this.touched.category = false
          this.$nextTick(() => this.$validator.reset())
        })
      } catch (err) {
        console.log(err)
        alert.type = 'error'
        if (this.oper === "add") {
          alert.text = this.$t(`muc.record_was_not_added`)
        }else if (this.oper === "upd") {
          alert.text = this.$t(`muc.record_was_not_updated`)
        }
        // alert.footer = this.$t(err)
        await Swal.fire(alert)
      }
    }
  },
  watch: {},

  computed: {
    ...mapState({}),
    isBetweenCondition() {
      return this.criterion.criterion_condition === 'between'
    }
  }
}
</script>
<style lang="scss" scoped>
.md-button + .md-button {
  margin-left: 10px;
}

.md-layout, .md-layout-item {
  width: unset !important;
}

.md-radio {
  display: flex;
}
</style>

